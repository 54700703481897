<template>
  <div class="container" v-if="$apollo.loading">Loading..</div>
  <div class="container" :class="elmClass" v-else>
    <header id="main-header">
      <ProgressBar :duration="getScreenTimer" @onload-end="setNextScreen" />
      <div class="main-header-inner">
        <h1>{{ title }}</h1>
        <Inline-svg :src="require('@/assets/img/batteliek-logo.svg')"/>
      </div>
    </header>
    <component :is="currentTab" :global="global" :screenId="id" />
  </div>
</template>

<script>

import { ref } from 'vue';

import InlineSvg from 'vue-inline-svg';
import gql from 'graphql-tag'
import MenuBarData from './components/MenuBarData.vue'
import MenuRestoData from './components/MenuRestoData.vue'
import MenuRestoBreakData from './components/MenuRestoBreakData.vue'
import ProgressBar from './components/ProgressBar.vue'

const globalQuery = gql`
  query getGlobal {
    global: globalSet(id: "3002") {
      id
      ... on menu_GlobalSet {
        id
        name
        headerImage {
          url,
          dateUpdated
        },
        screenTimer,
        kitchenBreakEnabled,
        kitchenBreaks {
          ... on kitchenBreaks_kitchenBreak_BlockType {
            kitchenBreakEnd
            kitchenBreakStart
          }
        },
        kitchenBreakMessage,
        kitchenBreakTitle
      }
    }
  }
`

export default {
  data() {
    return {
      currentTab: '',
      title: '',
      slug: '',
      screenNumber: 0,
      global: [],
      screens: [
        {
          slug: 'bar',
          title: 'Bar',
          view: 'MenuBarData',
          class: 'page-bar'
        },
        {
          slug: 'resto-1',
          title: 'Resto',
          view: 'MenuRestoData',
          id: 'screen1',
          class: 'page-resto page-resto-1'
        },
        {
          slug: 'resto-2',
          title: 'Resto',
          view: 'MenuRestoData',
          id: 'screen2',
          class: 'page-resto page-resto-2'
        }
      ],
      screenBreak: {
        slug: 'resto-break',
        title: 'Resto',
        view: 'MenuRestoBreakData',
        class: 'page-resto-break'
      },
    }
  },
  components: {
    MenuBarData,
    MenuRestoData,
    MenuRestoBreakData,
    ProgressBar,
    InlineSvg
  },
  computed: {
    getScreenTimer() {
      return (this.global && this.global.screenTimer) ? this.global.screenTimer : '';
    },
    path() {
      return window.location.pathname;
    }
  },
  methods: {
    // Add logic kitchBreak
    setNextScreen: function() {
      let screen = this.getNextScreen();
      
      if (this.path != '/') {
        screen = this.getScreenByPath(this.path);
      }

      this.setScreen(screen);
    },
    getNextScreen: function() {
      const kitchenBreakEnabled = this.global.kitchenBreakEnabled;
      let screen = this.getScreen(this.screenNumber);
      
      // Kitchen Break
      let kitchenBreakActive = false;
      if (kitchenBreakEnabled) {
        const now = Date.now();
        const kitchenBreaks = this.global.kitchenBreaks;
        if (kitchenBreaks.length) {
          kitchenBreaks.forEach((item) => {
            const start = item.kitchenBreakStart;
            const end = item.kitchenBreakEnd;
            if (start && end) {
              if ((this.screenNumber == 1 || this.screenNumber == 2) && (now >= Date.parse(start) && now <= Date.parse(end))) {
                screen = this.screenBreak;
                kitchenBreakActive = true;
              }
            }
          });
        }
      }

      // Set next screen number
      if (kitchenBreakActive && this.screenNumber === 1) {
        this.screenNumber = 0;
      } else if (this.screenNumber < this.screens.length - 1) {
        this.screenNumber++;
      } else {
        this.screenNumber = 0;
      }

      return screen;
    },
    getScreen: function(num) {
      return this.screens[num];
    },
    getScreenByPath: function(path) {
      let s = path.replace(/\//g,'');
      return this.screens.find( ({slug}) => slug === s );
    },
    setScreen: function(screen) {
      this.title = ref(screen.title);
      this.currentTab = screen.view;
      this.slug = screen.slug;
      this.id = screen.id;
      this.elmClass = screen.class;
    }
  },
  mounted() {
    this.setNextScreen();
  },
  apollo: {
    global: {
      query: globalQuery,
      pollInterval: 30000
    }
  }
}
</script>