<!-- MenuCategory.vue -->
<script>
import MenuBarItem from './MenuBarItem.vue'
export default {
    components: {
        MenuBarItem
    },
    props: [
        'items',
        'category',
        'isBatteliek'
    ],
    computed: {
        getCategory() {
            return (this.category && this.category.length && this.category[0]) ? this.category[0] : ''
        }
    }
}
</script>

<template>
    <div :class="{ 'menu-category': true , 'menu-category--batteliek': isBatteliek}">
        <header v-if="getCategory" :set="cat = getCategory">
            <div class="category-title" v-if="cat.title"><h2>{{ cat.title }}</h2></div>
            <table v-for="header in cat.priceHeader" :key="header.col1">
                <tbody>
                    <tr>
                        <td v-if="header.col1">{{ header.col1 }}</td>
                        <td v-if="header.col2">{{ header.col2 }}</td>
                        <td v-if="header.col3">{{ header.col3 }}</td>
                    </tr>
                </tbody>
            </table>
        </header>
        <div class="menu-items">
            <MenuBarItem 
                v-for="drink in items"
                :key="drink.id"
                :title="drink.title" 
                :drink="drink"
                :category="getCategory" />
        </div>
    </div>
</template>
