<template>
    <div class="menu">
      <div v-if="$apollo.loading">Loading..</div>
      <div v-else>
        <!--<div class="background-image" v-if="headerImage()"><img :src="headerImage()"></div>-->
        <div class="grid menu-bar-grid">
          <div class="grid-item">
            <MenuBarCategory :isBatteliek="true" v-for="category in categoryCell('col1', 'row1')" :items="menuFilter(category.id)" :category="categoryFilter(category.id)" :key="category.id" />
          </div>
          <div class="grid-item">
            <MenuBarCategory :isBatteliek="true" v-for="category in categoryCell('col2', 'row1')" :items="menuFilter(category.id)" :category="categoryFilter(category.id)" :key="category.id" />
          </div>
          <div class="grid-item">
            <MenuBarCategory :isBatteliek="true" v-for="category in categoryCell('col3', 'row1')" :items="menuFilter(category.id)" :category="categoryFilter(category.id)" :key="category.id" />
          </div>
          <div class="grid-item grid-item--dark" v-if="categoryCell('col1', 'row2').length">
            <MenuBarCategory v-for="category in categoryCell('col1', 'row2')" :items="menuFilter(category.id)" :category="categoryFilter(category.id)" :key="category.id" />
          </div>
          <div class="grid-item grid-item--dark" v-if="categoryCell('col2', 'row2').length">
            <MenuBarCategory v-for="category in categoryCell('col2', 'row2')" :items="menuFilter(category.id)" :category="categoryFilter(category.id)" :key="category.id" />
          </div>
          <div class="grid-item grid-item--dark" v-if="categoryCell('col3', 'row2').length">
            <MenuBarCategory v-for="category in categoryCell('col3', 'row2')" :items="menuFilter(category.id)" :category="categoryFilter(category.id)" :key="category.id" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import gql from 'graphql-tag'
import MenuBarCategory from './menu/MenuBarCategory.vue'

const menuQuery = gql`
  query getMenu {
    drinks: entries(section: "menu") {
      ...DrinkEntry
      ...BatteliekDrinkEntry
      ...TastingSetEntry
      ...FoodEntry
    }
  }

  fragment DrinkEntry on menu_drink_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_drink_Entry {
      id
      alcoholByVolume
      soldOut
      menuCategory {
        id
        title
        slug
      }
      drinkPrices {
        ... on drinkPrices_BlockType {
          id
          col1
          col2
          col3
        } 
      }
    }
  }

  fragment BatteliekDrinkEntry on menu_batteliekDrink_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_batteliekDrink_Entry {
      id
      alcoholByVolume
      label {
        url
      }
      soldOut
      menuCategory {
        id
        title
        slug
      }
      drinkPrices {
        ... on drinkPrices_BlockType {
          id
          col1
          col2
          col3
        } 
      }
      drinkReference {
        ... on drinks_beer_Entry {
          id
          alcoholByVolume
          drinkType {
            id
            title
          }
          label {
            url
          }
          description
        }
        ... on drinks_lemonade_Entry {
          id
          label {
            url
          }
          description
        }
        ... on drinks_spirit_Entry {
          id
          alcoholByVolume
          label {
            url
          }
          description
        }
      }
    }
  }

  fragment TastingSetEntry on menu_tastingSet_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_tastingSet_Entry {
      id
      soldOut
      menuCategory {
        id
        title
        slug
      }
      price
    }
  }

  fragment FoodEntry on menu_food_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_food_Entry {
      id
      soldOut
      menuCategory {
        id
        title
        slug
      }
      price
    }
  }
  `
const categoriesQuery = gql`
  query getCategories {
    categories(group: "menuBar") {
      id
      title
      ... on menuBar_Category {
        id
        barRow
        barColumn
        priceHeader {
          col1
          col2
          col3
        }
        title
        slug
      }
    }
  }
`
// const globalQuery = gql`
//   query getGlobal {
//     global: globalSet(id: "2049") {
//       id
//       ... on menu_GlobalSet {
//         id
//         name
//         headerImage {
//           url
//         }
//       }
//     }
//   }
// `

export default {
    data () {
        return {
          entries: [],
          drinks: [],
          categories: [],
          rootUrl: process.env.VUE_APP_SITE_URL
        }
    },
    props: [ 'global' ],
    components: {
      MenuBarCategory
    },
    apollo: {
      drinks: {
        query: menuQuery,
        pollInterval: 2000
      },
      categories: categoriesQuery,
    },
    methods: {
      menuFilter(category) {
        return this.drinks.filter(item => Array.isArray(item.menuCategory) && item.menuCategory.length && item.menuCategory[0].id == category)
      },
      categoryFilter(category) {
        return this.categories.filter(item => item && item.id == category)
      },
      headerImage() {
        if (this.global && this.global.headerImage && this.global.headerImage.length) {
          const image = this.global.headerImage[0];
          const date  = Date.parse(image.dateUpdated) / 1000;
          return this.rootUrl + image.url + '?r=' + date;
        } else {
          return '';
        }
      },
      categoryCell(col, row) {
        const sortedCategories = [...this.categories]
        sortedCategories.sort((a, b) => a['lft'] - b['left'])
        return sortedCategories.filter(item => item && item.barColumn == col && item.barRow == row)
      }
    }
}
</script>