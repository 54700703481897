<template>
    <div class="menu">
      <div v-if="$apollo.loading">Loading..</div>
      <div v-else>
        <div class="grid menu-resto-grid">
          <div class="grid-item">
            <MenuRestoCategory 
              v-for="category in categoryColumn('col1')" 
              :key="category.id" 
              :category="categoryFilter(category.id)" 
              :items="menuFilter(category.id)" 
            />
          </div>
          <div class="grid-item">
            <MenuRestoCategory 
              v-for="category in categoryColumn('col2')" 
              :key="category.id" 
              :category="categoryFilter(category.id)" 
              :items="menuFilter(category.id)" 
            />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import gql from 'graphql-tag'
import MenuRestoCategory from './menu/MenuRestoCategory.vue'

const menuQuery = gql`
  query getMenu {
    items: entries(section: "menuResto") {
      id
      title
      ... on menuResto_default_Entry {
        id
        allergens {
          id
          slug
          title
        }
        menuItemDescription
        menuRestoCategory {
          slug
          title
          id
          ... on menuResto_Category {
            id
            screen
          }
        }
        priceHeader {
          col1
          col2
          col3
        }
        restoPrices {
          ... on restoPrices_BlockType {
            id
            col1
            col2
            col3
          }
        }
        vegi
        vegan
        soldOut
      }
    }
  }
  `
const categoriesQuery = gql`
  query getCategories {
    categories(group: "menuResto") {
      id
      title
      ... on menuResto_Category {
        id
        title
        slug
        categoryImage {
          url
        }
        column
        lft
        screen
      }
    }
  }
`

export default {
    data () {
        return {
          items: [],
          categories: [],
        }
    },
    components: {
      MenuRestoCategory
    },
    props: [ 'screenId' ],
    apollo: {
      items: {
        query: menuQuery,
        pollInterval: 2000
      },
      categories: {
        query: categoriesQuery,
        pollInterval: 2000
      },
    },
    methods: {
      menuFilter(category) {
        return this.items.filter(item => Array.isArray(item.menuRestoCategory) && item.menuRestoCategory.length && item.menuRestoCategory[0].id == category && item.menuRestoCategory[0].screen == this.screenId)
      },
      categoryFilter(category) {
        return this.categories.filter(item => item && item.id == category && item.screen == this.screenId)
      },
      categoryColumn(col) {
        const sortedCategories = [...this.categories]
        sortedCategories.sort((a, b) => a['lft'] - b['left'])
        return sortedCategories.filter(item => item && item.column == col)
      }
    }
}
</script>